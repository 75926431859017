<template>
  <b-card class="p-1">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <b-form-group class="w-50 mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Wyszukaj zamówienie" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Wyczyść </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>
    <b-table
      responsive
      bordered
      striped
      hover
      :items="cooperationRecords"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn">
      <template #cell(actions)="row">
        <div class="d-flex flex-column align-items-center">
          <b-button
            :to="{ name: 'orders-show', params: { id: row.item.id } }"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="btn table-btn"
            size="sm">
            Zobacz
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

import { all } from '@/http/orders';

import { StatusFormatter } from '@/formatters/StatusFormatter';

export default {
  mixins: [NotificationMixin],

  data() {
    return {
      cooperationRecords: [],
      filter: '',
      filterOn: [],
      fields: [
        { key: 'tetaOrderNumber', label: 'TETA ID', sortable: true },
        { key: 'orderData.email', label: 'Email', sortable: true },
        { key: 'orderData.phone', label: 'Telefon', sortable: true },
        { key: 'total_price', label: 'Wartość', sortable: true },
        { key: 'delivery_price', label: 'Wartość dostawy', sortable: true },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
          formatter: (value, key, item) => {
            return StatusFormatter(item);
          },
        },
        { key: 'payment_method', label: 'Płatność', sortable: true },
        { key: 'delivery_method', label: 'Sposób dostawy', sortable: true },
        { key: 'created_at', label: 'Data złożenia', sortable: true },
        { key: 'actions', label: 'Akcje', class: 'w-25' },
      ],
    };
  },

  async mounted() {
    this.fetchOrders();
  },

  methods: {
    async fetchOrders() {
      try {
        const { data } = await all();

        this.cooperationRecords = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił problem z pobraniem zgłoszeń współpracy. Skontaktuj się ze swoim developerem.',
        );
      }
    },
  },
};
</script>
