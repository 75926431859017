export const StatusFormatter = ({ status }) => {
  switch (status) {
    case 'new':
      return 'Nowe';

    case 'pending':
      return 'Oczekujące';

    case 'cancelled':
      return 'Anulowane';

    case 'completed':
      return 'Opłacone';
  }
};
